import React from 'react'
import { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import '../../Assets/css/about.css'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import Aboutimg from "../Assets/images/aboutimg.svg";
const Thankyou = () => {
  const planDetails = useSelector(
    (state) => state.persistedReducer.home.plan_details
  );
  console.log(planDetails)
  useEffect(() => {
    window.scrollTo(0, 0)
    purchase_event()
  // window.gtag('event', 'conversion', {
  //     'send_to': 'AW-11259165471/F_pbCPnOx50ZEJ_25Pgp',
  //     'value': planDetails?.final_amount,
  //     'currency': planDetails?.currency,
  //     'transaction_id': planDetails?.id
  // });

  }, [])


  const purchase_event = (product) => {
    if (window.gtag) {
      window.dataLayer = window.dataLayer || [];
      function gtag() { window.dataLayer.push(arguments); }
      gtag('event', 'conversion', {
        // 'send_to': 'AW-11259165471/F_pbCPnOx50ZEJ_25Pgp',
        'value': planDetails?.final_amount,
        'currency': planDetails?.currency,
        'transaction_id': planDetails?.id
      }
    );
    }
  }





  return (
    <div className='about-page'>
       <Helmet>
        <meta charSet="utf-8" />
        <title>Thank You | Mid Journey</title>
        <meta name="description" content="Experience your creative spirit with prompt AI-generated images. With Mid Journey you can generate limitless images through Mid Journey AI image generator"></meta>
        <link rel="canonical" to= {window.location.href} />
      </Helmet>
      <Container className='pt-5'>
        <Row className='align-items-center mt-10'>
          <Col className='text-center' lg={12}>

           <div><h1 className='text-center mt-5 mb-5'> 🤝 Thank you for subscribing to Mid Journey AI. </h1>
           <Link className='theme-btn' to='/user/image-generator' > Generate Images</Link>
           </div>

          </Col>

         
            </Row>
            
          </Container>
        </div>
        )
}

        export default Thankyou